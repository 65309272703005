import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiText } from '@uireact/text';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { TabsExample } from './utils/';
import { UiTabs, UiTabItem } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1 {...{
      "id": "uitabs"
    }}>{`UiTabs`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/tabs/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component that renders a tabs for an options selection experience. When a tab is clicked it executes a callback and it passes an identifier that
to identify what tab was clicked. The UiTabItem allows for any custom type to be passed so the callback function is correctly typed.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/tabs`}</p>
    </blockquote>
    <h2 {...{
      "id": "uitabs-1"
    }}>{`UiTabs`}</h2>
    <Playground __position={1} __code={'<TabsExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      packageJson,
      Playground,
      TabsExample,
      UiTabs,
      UiTabItem,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <TabsExample mdxType="TabsExample" />
    </Playground>
    <br />
    <p>{`TabsExample:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const TabsExample: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);

  const handleTabClick = React.useCallback(
    (identifier: number) => {
      setSelectedTab(identifier);
    },
    [setSelectedTab]
  );

  return (
    <UiCard noPadding>
      <UiTabs rounded>
        <UiTabItem<number> selected={selectedTab === 1} identifier={1} handleClick={handleTabClick}>
          <UiText>Fruits</UiText>
        </UiTabItem>
        <UiTabItem<number> selected={selectedTab === 2} identifier={2} handleClick={handleTabClick}>
          <UiText>Vegetables</UiText>
        </UiTabItem>
        <UiTabItem<number> selected={selectedTab === 3} identifier={3} handleClick={handleTabClick}>
          <UiText>Meats</UiText>
        </UiTabItem>
      </UiTabs>
      {selectedTab === 1 && <Fruits />}
      {selectedTab === 2 && <Veggies />}
      {selectedTab === 3 && <Meats />}
    </UiCard>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "uitabs-props"
    }}>{`UiTabs Props`}</h3>
    <Props of={UiTabs} mdxType="Props" />
    <h3 {...{
      "id": "uitabitem-props"
    }}>{`UiTabItem Props`}</h3>
    <Props of={UiTabItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      